import keycloak from "../Keycloak";
import { PostOrderData } from "./types";
import axios, { AxiosRequestConfig } from "axios";

const ORDER_URL = "https://transaction.aigotsrl-api.com/";
export const BUSINESS_URL = "https://business.aigotsrl-api.com";

const refreshAuthToken = async (): Promise<string | undefined> => {
  await keycloak.updateToken();

  return keycloak.token;
};

const businessClient = axios.create({
  baseURL: BUSINESS_URL,
});

businessClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig & { _retry?: boolean } =
      error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      console.error("Unauthorized request. Attempting to refresh token...");
      originalRequest._retry = true;

      try {
        const newAuthToken = await refreshAuthToken();

        if (!newAuthToken) {
          throw new Error("Token refresh failed");
        }

        // Update the authorization header with the new token
        if (!originalRequest.headers) {
          originalRequest.headers = {};
        }

        originalRequest.headers["Authorization"] = `Bearer ${newAuthToken}`;

        // Retry the original request with the new token
        return businessClient.request(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

const orderClient = axios.create({
  baseURL: ORDER_URL,
});

export async function postOrder(
  data: PostOrderData,
  authToken: null | string
): Promise<Order> {
  try {
    const response = await orderClient.post("/order", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authToken}`,
      },
    });

    return response.data as Order;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function searchDomains(
  domainName: string,
  authToken: string | null
): Promise<string[]> {
  const SEARCH_DOMAINS_URL = `/domain/search-domains?domain=${domainName}`;

  try {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    if (authToken) {
      headers["Authorization"] = `Bearer ${authToken}`;
    }

    const response = await businessClient.get(SEARCH_DOMAINS_URL, { headers });
    return response.data.domains;
  } catch (error) {
    console.error("Error searching domains:", error);
    return [];
  }
}

export async function fetchContractPdf(
  id: string,
  authToken: string
): Promise<Blob> {
  const CONTRACT_URL = `/orders/contract-pdf/${id}`;

  try {
    const response = await businessClient.get(CONTRACT_URL, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export interface Contract {
  _id: string;
  name: string;
  content: string[];
  preferences: string[];
}

export const fetchContractByOrderId = async (
  orderId: string,
  authToken: string
): Promise<Contract> => {
  const CONTRACT_URL = `/orders/${orderId}/contract`;

  try {
    const response = await businessClient.get(CONTRACT_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error("Error fetching contract:", err);
    return Promise.reject(err);
  }
};

export interface Order {
  _id: string;
  name: string;
  lastName: string;
  companyName: string;
  domainName: string;
  taxId: string;
  companyCode: string;
  country: string;
  province: string;
  destinationCode: string;
  city: string;
  cap: string;
  address: string;
  targetPec: string;
  plan?: string;
  packages?: string[];
  discountCode?: string;
  planType?: string;

  metadata?: {
    restaurantInfo: Record<string, any>;
    registrationStep: string;
  };
}

export const getOrderById = async (
  orderId: string,
  authToken: string
): Promise<Order> => {
  const ORDER_URL = `/orders/${orderId}`;

  try {
    const { data } = await businessClient.get(ORDER_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return data as Order;
  } catch (err) {
    console.error("Error fetching order:", err);
    return Promise.reject(err);
  }
};

export const getCurrentOrder = async (
  authToken: string
): Promise<Order | null> => {
  const ORDER_URL = `/orders`;

  try {
    const { data } = await businessClient.get(ORDER_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return data as Order;
  } catch (err) {
    return null;
  }
};

export const patchOrder = async (
  orderId: string,
  patchObj: Record<string, any>,
  authToken: string,
  signal?: AbortSignal
): Promise<Order> => {
  const ORDER_URL = `/orders`;

  try {
    const { data } = await businessClient.patch(
      ORDER_URL,
      {
        id: orderId,
        ...patchObj,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        signal,
      }
    );

    return data as Order;
  } catch (err) {
    console.error("Error updating order:", err);
    return Promise.reject(err);
  }
};

export interface Plan {
  _id: string;
  name: string;
  monthlyPrice: number;
  annualPrice: number;
  currency: string;
  description: string;
  isDefault: boolean;
  advertisingText: string;
  includedText: string;
}

export const getPlans = async (authToken: string): Promise<Plan[]> => {
  const { data } = await businessClient.get("/plans", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  return data as Plan[];
};

export interface Package {
  _id: string;
  name: string;
  monthlyPrice: number;
  annualPrice: number;
  currency: string;
  includedText: string[];
}

export const getPackages = async (authToken: string): Promise<Package[]> => {
  const { data } = await businessClient.get("/packages", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  return data as Package[];
};

export interface DiscountCode {
  _id: string;
  code: string;
  label: string;
  discount: number;
}

export const getDiscountCode = async (
  code: string,
  authToken: string
): Promise<DiscountCode> => {
  const { data } = await businessClient.get(`/discount-codes/${code}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  return data as DiscountCode;
};

export interface UserInfo {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  preferred_username: string;
  sub: string;
}

export enum EPaymentProvider {
  STRIPE = "STRIPE",
}

export enum EPaymentMethod {
  CARD = "CARD",
}

export enum EPaymentStatus {
  PENDING = "PENDING",
}

export enum ESource {
  WEB = "web",
}

export enum ERecurrency {
  EVERY_WEEK = "0 0 * * 0",
  EVERY_WEEKDAY = "0 0 * * 1-5",
  EVERY_WEEKEND = "0 0 * * 6,0",
  EVERY_MONTH = "0 0 1 */1 *",
  EVERY_QUARTER = "0 0 1 */3 *",
  EVERY_6_MONTHS = "0 0 1 */6 *",
  EVERY_YEAR = "0 0 1 0 *",
}

export type SupportedCurrency = "eur" | "usd";

export interface BillingInformation {
  name: string;
  lastName: string;
  address: string;
  city: string;
  country: string;
  postalCode: string;
  companyName: string;
  taxId: string;
  email: string;
}

export interface PaymentPlatform {
  provider: string;
  externalId: string;
}

export interface CreatePaymentSessionRequest {
  name: string;
  provider: EPaymentProvider;
  method: EPaymentMethod;
  source: ESource;
  recurrency: ERecurrency;
  currency: SupportedCurrency;
  items: Array<{
    id: string;
    name: string;
    quantity: number;
    price: number;
  }>;
  billingInformation: BillingInformation;
  offer: {
    paymentPlatforms: PaymentPlatform[];
  };
  total: number;
  status: EPaymentStatus;
  email: string;
}

export const createPaymentSession = async (
  data: CreatePaymentSessionRequest
): Promise<{ url: string }> => {
  try {
    const response = await orderClient.post("/payment/create-session", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Payment session creation failed:", error);
    throw error;
  }
};

export const getInfoByTaxId = async (
  taxId: string,
  authToken: string
): Promise<Record<string, any>> => {
  try {
    const response = await businessClient.get(
      `/open-api/company-info/${taxId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching tax id info:", error);
    return {};
  }
};

interface PaymentRequest {
  provider: "STRIPE";
  items: Array<{
    id: string;
    quantity: number;
    name: string;
  }>;
  recurrency?: ERecurrency;
  offer?: {
    paymentPlatforms: Array<{
      provider: string;
      externalId: string;
    }>;
  };
  externalPaymentId: string;
  billingInformation?: BillingInformation;
  source: string;
  successUrl?: string;
  cancelUrl?: string;
  orderId: string;
}

export const createPayment = async (paymentData: PaymentRequest) => {
  try {
    const response = await orderClient.post("/payment", paymentData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.data.url) {
      throw new Error("Payment creation failed");
    }

    window.location.href = response.data.url; // Redirect to Stripe checkout
  } catch (error) {
    console.error("Payment creation failed:", error);
    throw error;
  }
};
